@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Italianno&display=swap');


body {
  padding: 2px;
  overflow-y: scroll; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  font-family: 'Inter', sans-serif;
  /* Hide the vertical scrollbar */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
  font-size: small; /* Default font size for larger screens */
}

/* Hide scrollbar for WebKit browsers */
body::-webkit-scrollbar {
  display: none;
}

/* Extra small font size for small to medium devices */
@media (max-width: 768px) {
  body {
    font-size: x-small; /* Set font size to extra small on small to medium devices */
  }
}


body::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}



.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}


.jimu-primary-loading:before,
.jimu-primary-loading:after {
  position: absolute;
  top: 0;
  content: '';
}

.jimu-primary-loading:before {
  left: -19.992px;
}

.jimu-primary-loading:after {
  left: 19.992px;
  -webkit-animation-delay: 0.32s !important;
  animation-delay: 0.32s !important;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after,
.jimu-primary-loading {
  background: #076fe5;
  -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  width: 13.6px;
  height: 32px;
}

.jimu-primary-loading {
  text-indent: -9999em;
  margin: auto;
  position: absolute;
  right: calc(50% - 6.8px);
  top: calc(50% - 16px);
  -webkit-animation-delay: 0.16s !important;
  animation-delay: 0.16s !important;
}

@-webkit-keyframes loading-keys-app-loading {

  0%,
  80%,
  100% {
    opacity: .75;
    box-shadow: 0 0 #076fe5;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #076fe5;
    height: 40px;
  }
}

@keyframes loading-keys-app-loading {

  0%,
  80%,
  100% {
    opacity: .75;
    box-shadow: 0 0 #076fe5;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #076fe5;
    height: 40px;
  }
}


.text-strike {
  position: relative;
}

.text-strike::after {
  content: '';
  position: absolute;
  top: 50%;
  /* Adjust as needed */
  left: 0;
  right: 0;
  opacity: 0.75;
  border-bottom: 3px solid #BB0000;
  /* Adjust thickness here */
}



.pyramid-loader {
  position: relative;
  width: 300px;
  height: 300px;
  display: block;
  transform-style: preserve-3d;
  transform: rotateX(-20deg);
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotateY(360deg);
  }
}

.pyramid-loader .wrapper .side {
  width: 70px;
  height: 70px;
  /* you can choose any gradient or color you want */
  /* background: radial-gradient( #2F2585 10%, #F028FD 70%, #2BDEAC 120%); */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform-origin: center top;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.pyramid-loader .wrapper .side1 {
  transform: rotateZ(-30deg) rotateY(90deg);
  background: conic-gradient(#2BDEAC, #F028FD, #D8CCE6, #2F2585);
}

.pyramid-loader .wrapper .side2 {
  transform: rotateZ(30deg) rotateY(90deg);
  background: conic-gradient(#2F2585, #D8CCE6, #F028FD, #2BDEAC);
}

.pyramid-loader .wrapper .side3 {
  transform: rotateX(30deg);
  background: conic-gradient(#2F2585, #D8CCE6, #F028FD, #2BDEAC);
}

.pyramid-loader .wrapper .side4 {
  transform: rotateX(-30deg);
  background: conic-gradient(#2BDEAC, #F028FD, #D8CCE6, #2F2585);
}

.pyramid-loader .wrapper .shadow {
  width: 60px;
  height: 60px;
  background: #8B5AD5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: rotateX(90deg) translateZ(-40px);
  filter: blur(12px);
}


.slick-dots {
  bottom: 20px;
  /* Adjust the distance from the bottom as needed */
}

.slick-dots li {
  margin: 0 5px;
  /* Adjust the distance between dots as needed */
}

.slick-dots li button {
  width: 20px;
  /* Adjust the width of each dot as needed */
  height: 20px;
  /* Adjust the height of each dot as needed */
  border-radius: 50%;
  /* Make dots circular */
  background-color: #ffffff;
  /* Adjust the color of inactive dots */
}

.slick-dots li.slick-active button {
  background-color: #BB0000;
  /* Adjust the color of the active dot */
}


.product-slider .slick-list {
  margin: 0px 0px 0px 2px;
  overflow: hidden;
  
}

.zoom-image {
  /* Basic image styles */
  width: 300px;
  height: 200px;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
}

.zoom-image:hover {
  transform: scale(1.2); /* Zoom on hover */
  cursor: zoom-in; /* Change cursor to zoom icon */
}

.zoom-image:active { /* Add drag effect while holding mouse */
  transform: scale(1.5);
}


.loader-container {
  position: relative;
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Adjust the size as needed */
  margin: 0 auto;
}

.loader-image {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px; /* Adjust the size as needed */
  height: 80px; /* Adjust the size as needed */
  background-image: url('./assets/loader.png'); /* Replace with your image path */
  background-size: cover;
  border-radius: 50%;
}



.loader-spinner {
  position: fixed;
  top: 44%;
  left: 50%;
  width: 80px; /* Adjust the size as needed */
  height: 80px;
  transform: translate(-50%, -50%);
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid #BB0000; /* Adjust spinner color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}